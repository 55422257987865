<template>
  <div>
    <vx-card actionable class="cardx" title="Admin Spoc Dashboard">
      <vs-row type="flex">
        <!--Team -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <vs-select class="selectExample" label="Team" v-model="selected_team">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in teams" />
          </vs-select>
        </vs-col>
        <!--City -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" v-if="selected_team == 'CM'">
          <vs-select class="selectExample" label="City" v-model="selected_city">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in cities" />
          </vs-select>
        </vs-col>
        <!--City classification -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3"
          v-if="selected_team == 'CM' && selected_city != ''">
          <vs-select class="selectExample" label="City Classification" v-model="selected_city_classification">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in city_classification" />
          </vs-select>
        </vs-col>
        <!--Course -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" v-if="selected_team == 'ExEd'">
          <vs-select class="selectExample" label="Courses" v-model="selected_course">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in courses" />
          </vs-select>
        </vs-col>
        <!--Spocs -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" v-if="
          selected_team != 'CM' &&
          selected_team != 'ExEd' &&
          selected_team != ''
        ">
          <v-select placeholder="Spocs" style="z-index: 1000; padding-top: 7%" class="w-full" v-model="selected_spoc"
            :options="spocs" label="Spocs"></v-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" style="margin-top: 1.5%">
          <vs-button color="dark" type="filled" @click="getCityData()">GO</vs-button>
          <vs-button v-if="table_data.length != 0" color="primary" style="margin-left:5px" type="filled"
            @click="downloadCsvfile">Download</vs-button>
        </vs-col>
      </vs-row>
      <!-- <vx-card actionable class="cardx" v-if="loaded"> -->
      <div>
        <vs-row v-if="table_data.length != 0" type="flex" style="margin-top: 2%">
          <vs-col vs-order="1" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" style="font-size: 19px">
            <b>Call Logs</b>
          </vs-col>
          <vs-col vs-order="1" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" style="font-size: 19px">
            <b>Work Logs</b>
          </vs-col>
        </vs-row>
        <div ref="table1"></div>

      </div>
    </vx-card>
    <SpocDashboardCallLogPopup></SpocDashboardCallLogPopup>
    <SpocDashboardAdminToUpdate></SpocDashboardAdminToUpdate>
    <SpocDashboardAdminWorkLog></SpocDashboardAdminWorkLog>
    <SpocDashboardCmNetEnquiries></SpocDashboardCmNetEnquiries>
    <SpocDBAdminIVRallCalls></SpocDBAdminIVRallCalls>
  </div>
</template>

<script>
import { TabulatorFull as Tabulator } from "tabulator-tables";
import vSelect from "vue-select";
import eventbus from "../components/eventbus";
import constants from "../../constants.json";
import axios from "axios";
import SpocDashboardCallLogPopup from "../components/pagesComponents/SpocDashboardAdminCallLog.vue";
import SpocDashboardAdminToUpdate from "../components/pagesComponents/SpocDashboardAdminToUpdate.vue";
import SpocDashboardAdminWorkLog from "../components/pagesComponents/SpocDashboardAdminWorkLog.vue";
import SpocDashboardCmNetEnquiries from "../components/pagesComponents/SpocDashboardCMNetenquiries.vue";
import SpocDBAdminIVRallCalls from "../components/pagesComponents/SpocDBAdminIVRallCalls.vue";

export default {
  data() {
    return {
      tableHeaders: [
        {
          title: "Spoc Name",
          field: "spoc_name",
          hozAlign: "center",
          frozen: true,
          headerFilter: "list",
          headerFilterParams: { valuesLookup: true, clearable: true },
        },
        {
          title: "Reporting To",
          field: "reporting_manager",
          hozAlign: "center",
          frozen: true,
          headerFilter: "list",
          headerFilterParams: { valuesLookup: true, clearable: true },
        },
        {
          title: "Spoc Level",
          field: "user_tag",
          hozAlign: "center",
          headerFilter: "list",
          headerFilterParams: { valuesLookup: true, clearable: true },
        },
        {
          title: "City Tier",
          field: "city_classification",
          hozAlign: "center",
          headerFilter: "list",
          headerFilterParams: { valuesLookup: true, clearable: true },
        },
        {
          title: "Missed Calls",
          field: "missed_calls",
          hozAlign: "center",
          bottomCalc: "sum",
          // cellClick:function(e,cell){
          //   console.log("cell data", cell._cell.row.data.user_id, cell._cell.column.field);
          //   this.getData(cell._cell.row.data.user_id, cell._cell.column.field)
          //   }
        },
        {
          title: "Untracked",
          field: "untracked_calls",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "To Update",
          field: "to_update_calls",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "Call Logs Today",
          field: "today_calls_logs",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "IVR All Calls",
          field: "ivr_all_calls",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "IVR Missed Calls",
          field: "ivr_missed_calls",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "Untapped",
          field: "untapped",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "Delays",
          field: "delays",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "NE Delays",
          field: "ne_delays",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "Other Delays",
          field: "other_delays",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "Today",
          field: "today",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "Waiting",
          field: "waiting_calls",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        { title: "MHPS", field: "mhp", hozAlign: "center", bottomCalc: "sum" },
        {
          title: "Net Enquiries",
          field: "net_enquiries",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        { title: "Flag", field: "flag", hozAlign: "center", bottomCalc: "sum" },
      ],
      exedHeaders: [
        {
          title: "Spoc Name",
          field: "spoc_name",
          hozAlign: "center",
          frozen: true,
          headerFilter: "list",
          headerFilterParams: { valuesLookup: true, clearable: true },
        },
        {
          title: "Reporting To",
          field: "reporting_manager",
          hozAlign: "center",
          frozen: true,
          headerFilter: "list",
          headerFilterParams: { valuesLookup: true, clearable: true },
        },
        {
          title: "Spoc Level",
          field: "user_tag",
          hozAlign: "center",
          headerFilter: "list",
          headerFilterParams: { valuesLookup: true, clearable: true },
        },
        {
          title: "City Tier",
          field: "city_classification",
          hozAlign: "center",
          headerFilter: "list",
          headerFilterParams: { valuesLookup: true, clearable: true },
        },
        {
          title: "Missed Calls",
          field: "missed_calls",
          hozAlign: "center",
          bottomCalc: "sum",
          // formatter:"link",
          // cellClick:function(e, cell){alert("cell clicked - " + cell.getValue())}
        },
        {
          title: "Untracked",
          field: "untracked_calls",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "To Update",
          field: "to_update_calls",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "Call Logs Today",
          field: "today_calls_logs",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "Untapped",
          field: "untapped",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "Delays",
          field: "delays",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "NE Delays",
          field: "ne_delays",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "Other Delays",
          field: "other_delays",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "Today",
          field: "today",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        {
          title: "Waiting",
          field: "waiting_calls",
          hozAlign: "center",
          bottomCalc: "sum",
        },
        { title: "MHPS", field: "mhp", hozAlign: "center", bottomCalc: "sum" },
        {
          title: "Net Enquiries",
          field: "net_enquiries",
          hozAlign: "center",
          bottomCalc: "sum",
        },
      ],
      dashboard_data: [],
      cities: [],
      selected_city: "",
      city_classification: [],
      selected_city_classification: "",
      table_data: [],
      spoc_with_teams: [],
      selected_specific_team: "",
      selected_team: "",
      teams: [],
      spocs: [],
      selected_spoc: "",
      selected_course: "",
      courses: [],
    };
  },
  components: {
    SpocDashboardCallLogPopup,
    SpocDashboardAdminToUpdate,
    SpocDashboardAdminWorkLog,
    SpocDashboardCmNetEnquiries,
    SpocDBAdminIVRallCalls,
    "v-select": vSelect,
  },
  mounted() {
    this.getTeams();
    // this.getDashboardData();
  },
  watch: {
    selected_team: function (val) {
      this.selected_specific_team = val;
      this.table_data = [];
      this.spocs = [];

      this.courses = [];
      this.selected_spoc = "";
      if (val == "CM") {
        // this.cities = [
        //   ...new Set(this.spoc_with_teams.map((item) => item.city)),
        // ];
        // Sorting cities alphabetically
        // this.cities.sort((a, b) => {
        //   if (a < b) return -1;
        //   return a > b ? 1 : 0;
        // });

        let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
        this.cities = [];
        let citys = [
          ...new Set(this.spoc_with_teams.map((item) => item.city)),
        ];
        citys.forEach(element => {
        if(city_options.includes(element)){
          this.cities.push(element);
        }
        })
      } else if (val == "ExEd") {
        this.courses = [
          ...new Set(
            this.spoc_with_teams.map((item) =>
              item.team == "ExEd" ? item.sub_team : ""
            )
          ),
        ];
        console.log("courses", this.courses);
      } else if (val == "SR") {
        this.spocs = [
          ...new Set(
            this.spoc_with_teams.map((item) =>
              item.team == val && item.sub_team == "SR"
                ? item.first_name + " " + item.last_name
                : ""
            )
          ),
        ];
        console.log("sr spocs", this.spocs);
      } else if (val == "GM") {
        this.spocs = [
          ...new Set(
            this.spoc_with_teams.map((item) =>
              item.team == val ? item.first_name + " " + item.last_name : ""
            )
          ),
        ];
        console.log("gm spocs", this.spocs);
      }
      // else if (val == "CR") {
      //   this.spocs = [
      //     ...new Set(
      //       this.spoc_with_teams.map((item) =>
      //         item.team == "SR" && item.sub_team == "Corporate Relations"
      //           ? item.first_name + " " + item.last_name
      //           : ""
      //       )
      //     ),
      //   ];
      //   console.log("cr spocs", this.spocs);
      // }
      // else if (val == "Collections") {
      //   this.spocs = [
      //     ...new Set(
      //       this.spoc_with_teams.map((item) =>
      //         item.team == "SR" && item.sub_team == "Collection"
      //           ? item.first_name + " " + item.last_name
      //           : ""
      //       )
      //     ),
      //   ];
      //   console.log("collections spocs", this.spocs);
      // }
      else if (val == "Placements") {
        console.log(val, "team");
        this.spocs = [
          ...new Set(
            this.spoc_with_teams.map((item) =>
              item.team == "SR" && item.sub_team == "Alumni SR"
                ? item.first_name + " " + item.last_name
                : ""
            )
          ),
        ];
        console.log("placements spocs", this.spocs);
      }

      // this.spocs.sort(function (a, b) {
      //   if (a > b) return 1;
      //   if (a < b) return -1;
      // });
      this.spocs.splice("", 1);
      this.spocs.unshift("All");

      this.courses.splice("", 1);
      // this.courses.unshift('All')
      this.getDashboardData(val);
    },
    selected_city: function (val) {
      this.city_classification = [];
      this.selected_city_classification = "";
      let classification = ["All"];
      for (let i = 0; i < this.spoc_with_teams.length; i++) {
        if (
          val == this.spoc_with_teams[i].city &&
          this.spoc_with_teams[i].team == "CM"
        ) {
          classification.push(this.spoc_with_teams[i].city_classification);
        }
      }
      console.log("classification", classification);
      // To get distinct values from an array
      this.city_classification = [
        ...new Set(classification.map((item) => item)),
      ];
    },
  },
  methods: {
    getTeams() {
      let url = `${constants.SERVER_API}getAllActiveSpocsForDashbaord`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getAllSpocWithTeams", response);
          this.spoc_with_teams = response.data;
          this.teams = [
            ...new Set(this.spoc_with_teams.map((item) => item.team)),
          ];
          this.teams.push("Placements");
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    getDashboardData(team) {
      let url = "";
      if (team == "CM") {
        url = `${constants.MILES_CM_BACK}getPresignedUrlForSpocDashboardData?file_name=adminSpocDashboardCm.json`;
      } else if (team == "ExEd") {
        url = `${constants.MILES_CM_BACK}getPresignedUrlForSpocDashboardData?file_name=adminSpocDashboardExed.json`;
      } else if (
        team == "Placements" ||
        team == "SR" ||
        team == "GM" ||
        team == "CR" ||
        team == "Collections"
      ) {
        url = `${constants.MILES_CM_BACK}getPresignedUrlForSpocDashboardData?file_name=adminSpocDashboardSr.json`;
      }
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("data", response);
          this.dashboard_data = response.data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getCityData() {
      this.table_data = [];
      if (this.selected_team == "CM") {
        if (this.selected_city_classification != "") {
          if (this.selected_city_classification === "All") {
            for (let i = 0; i < this.dashboard_data.length; i++) {
              if (this.selected_city == this.dashboard_data[i].city) {
                this.table_data.push(this.dashboard_data[i]);
              }
            }
          } else {
            for (let i = 0; i < this.dashboard_data.length; i++) {
              if (
                this.selected_city == this.dashboard_data[i].city &&
                this.selected_city_classification ==
                this.dashboard_data[i].city_classification
              ) {
                this.table_data.push(this.dashboard_data[i]);
              }
            }
          }
        }
        this.getTabulatorTable(this.table_data)
        console.log(this.table_data)
      } else if (this.selected_team == "SR") {
        if (this.selected_spoc === "All") {
          for (let i = 0; i < this.dashboard_data.length; i++) {
            if (
              this.selected_team == this.dashboard_data[i].team &&
              this.dashboard_data[i].sub_team == "SR"
            ) {
              this.table_data.push(this.dashboard_data[i]);
            }
          }
          this.getTabulatorTable(this.table_data)
        } else {
          for (let i = 0; i < this.dashboard_data.length; i++) {
            if (
              this.selected_spoc == this.dashboard_data[i].spoc_name &&
              this.selected_team == this.dashboard_data[i].team &&
              this.dashboard_data[i].sub_team == "SR"
            ) {
              this.table_data.push(this.dashboard_data[i]);
            }
          }
          this.getTabulatorTable(this.table_data)
        }
      } else if (this.selected_team == "GM") {
        if (this.selected_spoc === "All") {
          for (let i = 0; i < this.dashboard_data.length; i++) {
            if (this.selected_team == this.dashboard_data[i].team) {
              this.table_data.push(this.dashboard_data[i]);
            }
          }
        } else {
          for (let i = 0; i < this.dashboard_data.length; i++) {
            if (
              this.selected_spoc == this.dashboard_data[i].spoc_name &&
              this.selected_team == this.dashboard_data[i].team
            ) {
              this.table_data.push(this.dashboard_data[i]);
            }
          }
        }
        this.getTabulatorTable(this.table_data)
      } else if (this.selected_team == "CR") {
        if (this.selected_spoc === "All") {
          for (let i = 0; i < this.dashboard_data.length; i++) {
            if (
              this.dashboard_data[i].team == "SR" &&
              this.dashboard_data[i].sub_team == "Corporate Relations"
            ) {
              this.table_data.push(this.dashboard_data[i]);
            }
          }
        } else {
          for (let i = 0; i < this.dashboard_data.length; i++) {
            if (
              this.selected_spoc == this.dashboard_data[i].spoc_name &&
              this.dashboard_data[i].team == "SR" &&
              this.dashboard_data[i].sub_team == "Corporate Relations"
            ) {
              this.table_data.push(this.dashboard_data[i]);
            }
          }
        }
        this.getTabulatorTable(this.table_data)
      } else if (this.selected_team == "Collections") {
        if (this.selected_spoc === "All") {
          for (let i = 0; i < this.dashboard_data.length; i++) {
            if (
              this.dashboard_data[i].team == "SR" &&
              this.dashboard_data[i].sub_team == "Collection"
            ) {
              this.table_data.push(this.dashboard_data[i]);
            }
          }
        } else {
          for (let i = 0; i < this.dashboard_data.length; i++) {
            if (
              this.selected_spoc == this.dashboard_data[i].spoc_name &&
              this.dashboard_data[i].team == "SR" &&
              this.dashboard_data[i].sub_team == "Collection"
            ) {
              this.table_data.push(this.dashboard_data[i]);
            }
          }
        }
        this.getTabulatorTable(this.table_data)
      } else if (this.selected_team == "ExEd") {
        for (let i = 0; i < this.dashboard_data.length; i++) {
          if (this.selected_course == this.dashboard_data[i].sub_team) {
            this.table_data.push(this.dashboard_data[i]);
          }
        }
        this.getTabulatorTable(this.table_data)
      } else if (this.selected_team == "Placements") {
        if (this.selected_spoc === "All") {
          for (let i = 0; i < this.dashboard_data.length; i++) {
            if (
              // this.selected_team == this.dashboard_data[i].sub_team &&
              // this.dashboard_data[i].team == "SR"
              this.dashboard_data[i].team == "SR" &&
              this.dashboard_data[i].sub_team == "Alumni SR"
            ) {
              this.table_data.push(this.dashboard_data[i]);
            }
          }
        } else {
          for (let i = 0; i < this.dashboard_data.length; i++) {
            if (
              // this.selected_spoc == this.dashboard_data[i].spoc_name &&
              // this.selected_team == this.dashboard_data[i].sub_team &&
              // this.dashboard_data[i].team == "SR"
              this.selected_spoc == this.dashboard_data[i].spoc_name &&
              this.dashboard_data[i].team == "SR" &&
              this.dashboard_data[i].sub_team == "Alumni SR"
            ) {
              this.table_data.push(this.dashboard_data[i]);
            }
          }
        }
        this.getTabulatorTable(this.table_data)
      }
    },

    setSelection(e, cell) {
      console.log("hello",cell._cell.row.data.user_id);
      console.log("this.selected_team", this.selected_team);

      var selected_team = this.selected_team
      var selected_city_name = this.selected_city
      var Alldata = []
      var category
      let spoc_id = cell._cell.row.data.user_id;
      let category_name = cell._cell.column.field;
      let spocName = cell._cell.row.data.spoc_name;
      let field_name = {
        "missed_calls": "missed_calls",
        "untracked_calls": "untracked",
        "to_update_calls": "to_update",
        "today_calls_logs":"call_logs_today",
        "ivr_all_calls": "ivr_all_calls",
        "ivr_missed_calls": "ivr_missed_calls",
        "untapped": "untapped",
        "delays": "delays",
        "ne_delays": "ne_delays",
        "other_delays": "other_delays",
        "today": "today",
        "waiting_calls": "waiting",
        "mhp": "mhps",
        "net_enquiries": "net_enquiries",
        "flag": "flag",
      };
      category = field_name[category_name]
      console.log("user_id, field", selected_team, spoc_id, category, spocName, selected_city_name);
      if (spoc_id != null && category != "") {
        let url = "";
        if (selected_team == "CM") {
          url = `${constants.ADMIN_SERVER_API}getSpocDashboardForAdminDetails`;
        } else if (selected_team == "ExEd") {
          url = `${constants.ADMIN_SERVER_API}getIIMLSpocDashboardForAdminDetails`;
        } else {
          url = `${constants.ADMIN_SERVER_API}getSRDashboardForAdminDetails`;
        }
        let obj = {};
        if (category != "net_enquiries") {
          obj = {
            spoc_id: spoc_id,
            category: category,
          };
        }
        if (category == "net_enquiries") {
          obj = {
            spoc_id: spoc_id,
            category: category,
            selected_city: selected_city_name
          };
        }
        axios
          .get(url, {
            params: obj,
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            this.response = response;
            if (category === "net_enquiries") {
              eventbus.$emit(
                "spocDashboardAdminCM-NetEnquiries",
                // NetEnquiriesData,
                // spocName
                response.data,
                url,
                obj,
                category,
                spocName
              );
            }
            if (category === "missed_calls" || category === "untracked") {
              console.log("checking response", response);
              eventbus.$emit(
                "spocDashboardAdmin-calllog",
                response.data,
                url,
                obj,
                category,
                spocName
              );
            } else if (category === "to_update") {
              eventbus.$emit(
                "spocDashboardAdmin-toUpdate",
                response.data,
                url,
                obj,
                category,
                spocName
              );
            } else if (
              category === "delays" ||
              category === "ne_delays" ||
              category === "other_delays" ||
              category === "today" ||
              category === "waiting" ||
              category === "mhps" ||
              category === "untapped" ||
              category === "flag"
            ) {
              Alldata.push(response.data.data)
              this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
              eventbus.$emit("spocDashboardAdmin-WorkLog", [
                response,
                url,
                obj,
                spocName,
              ]);
            } else if (category === "ivr_all_calls" || category === "ivr_missed_calls") {
              eventbus.$emit(
                "spocDashboardAdmin-IVRcalls",
                response.data.data,
                url,
                obj,
                category,
                spocName
              );
            }


          })
          .catch((error) => {
            console.log(error)
            // this.handleError(error);
          });

        // console.log("AllGEtData",Alldata,this.selected_team)
      } else {
        this.$vs.notify({
          title: "Error",
          color: "danger",
        });
      }
    },

    getTabulatorTable(data) {
      var column_table;
      if (this.selected_team == "ExEd") {
        column_table = this.exedHeaders
      } else {
        column_table = this.tableHeaders
      }
      console.log("selectedTeam", this.selected_team)
      this.tabulator = new Tabulator(this.$refs.table1, {
        maxHeight: "75vh",
        data: this.table_data,
        rowFormatter: (function(row) {
          var evenColor = "#F5F6FA";
          var oddColor = "#FFF";
          row.getElement().style.backgroundColor = row._row.position % 2 === 0 ? evenColor : oddColor;
        }),
        columns: column_table,
        debugInvalidOptions: false,
        initialSort: [
          { column: "city_classification", dir: "asc" },
        ]
      });
      console.log("data", this.selected_team, data,)
      this.tabulator.on("cellClick", (e, cell) => this.setSelection(e, cell))
     

    },
    downloadCsvfile() {
      this.tabulator.download("csv", `Admin Spoc Dashboard.csv`, { bom: true });
    }
  },

};
</script>

<style>
.selectExample {
  width: 90%;
}

.tabulator {
  background-color: #f2f2f2;
}

.tabulator .tabulator-header {
  background-color: #f2f2f2;
}

.tabulator .tabulator-row:nth-child(odd) {
  background-color: #f2f2f2;
  color: rgb(0, 0, 0) !important;
}

.tabulator .tabulator-row:nth-child(even) {
  background-color: #e4e1e1;
  color: rgb(0, 0, 0) !important;
}

.tabulator .tabulator-header .tabulator-col {
  text-align: center;
}
.tabulator-calcs-bottom .tabulator-cell {
  background-color: rgb(0, 0, 0);
  color: #f2f2f2 !important;
}

.tabulator .tabulator-row .tabulator-cell[tabulator-field="missed_calls"] {
  color: rgb(79, 79, 204);
}

.tabulator .tabulator-row .tabulator-cell[tabulator-field="untracked_calls"] {
  color: rgb(79, 79, 204);
}

.tabulator .tabulator-row .tabulator-cell[tabulator-field="to_update_calls"] {
  color: rgb(79, 79, 204);
}

.tabulator .tabulator-row .tabulator-cell[tabulator-field="ivr_all_calls"] {
  color: rgb(79, 79, 204);
}

.tabulator .tabulator-row .tabulator-cell[tabulator-field="ivr_missed_calls"] {
  color: rgb(79, 79, 204);
}

.tabulator .tabulator-row .tabulator-cell[tabulator-field="untapped"] {
  color: rgb(79, 79, 204);
}

.tabulator .tabulator-row .tabulator-cell[tabulator-field="delays"] {
  color: rgb(79, 79, 204);
}

.tabulator .tabulator-row .tabulator-cell[tabulator-field="ne_delays"] {
  color: rgb(79, 79, 204);
}

.tabulator .tabulator-row .tabulator-cell[tabulator-field="other_delays"] {
  color: rgb(79, 79, 204);
}

.tabulator .tabulator-row .tabulator-cell[tabulator-field="today"] {
  color: rgb(79, 79, 204);
}

.tabulator .tabulator-row .tabulator-cell[tabulator-field="waiting_calls"] {
  color: rgb(79, 79, 204);
}

.tabulator .tabulator-row .tabulator-cell[tabulator-field="net_enquiries"] {
  color: rgb(79, 79, 204);
}

.tabulator .tabulator-row .tabulator-cell[tabulator-field="flag"] {
  color: rgb(79, 79, 204);
}

.tabulator .tabulator-row .tabulator-cell[tabulator-field="mhp"] {
  color: rgb(79, 79, 204);
}
</style>